import React from 'react';
/* eslint-disable */
import {
    FeatureBlockSection,
    FeatureBlockContent
} from '../../components/FeatureBlockElements';

import './tab-content.scss';

const TOSTabContent = () => {
    return (
        <FeatureBlockSection customClass="tos-tab-content tab-content">
            <FeatureBlockContent fullWidth>
                <div id="termsPage" className="terms-text">
                    <p className="date-revision">Updated March 10th, 2022</p>

                    <div className="terms-paragraph">
                        <h2 className="tab-content__title--primary">
                            Welcome to HoneyBook!
                        </h2>

                        <p>
                            HoneyBook, Inc. (&ldquo;HoneyBook&rdquo;,
                            &ldquo;we&rdquo;, &ldquo;us&rdquo;,
                            &ldquo;our&rdquo;) provides its services (described
                            below) to you through its website located at
                            www.HoneyBook.com (the &ldquo;Site&rdquo;) and
                            through its mobile applications and related services
                            (collectively, such services, including any new
                            features and applications, and the Site, the
                            &ldquo;Service(s)&rdquo;), subject to the following
                            Terms of Service (as amended from time to time, the
                            &ldquo;Terms of Service&rdquo;). We reserve the
                            right, at our sole discretion, to change or modify
                            portions of these Terms of Service at any time. If
                            we do this, we will post the changes on this page
                            and will indicate at the top of this page the date
                            these terms were last revised. Any changes will
                            become effective when posted. Your continued use of
                            the Services after changes are posted constitutes
                            your acceptance of the new Terms of Service. You
                            should visit this page before using the Services
                            each time to see if there have been any changes to
                            these Terms of Services.
                        </p>

                        <p>
                            In addition, when using certain services, you will
                            be subject to any additional terms applicable to
                            such services that may be posted on the Service from
                            time to time, including, without limitation, the
                            Privacy Policy located at{' '}
                            <a
                                className="link-act"
                                href="/terms/terms-of-service"
                                title="HoneyBook Terms and Conditions"
                            >
                                https://www.HoneyBook.com/terms/terms-of-service
                            </a>
                            . All such terms are hereby incorporated by
                            reference into these Terms of Service.{' '}
                        </p>

                        <p>
                            PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS
                            THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER
                            IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
                            REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO
                            ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU
                            SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND
                            FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE
                            PERMITTED TO PURSUE CLAIMS AGAINST HONEYBOOK ON AN
                            INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER
                            IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING,
                            (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
                            (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY
                            RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT
                            BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US
                            RESOLVED BY A JURY OR IN A COURT OF LAW.
                        </p>

                        <h2 className="terms-title">
                            1. Access and Use of the Service
                        </h2>

                        <p>
                            <b className="terms-marker">
                                Services Description:
                            </b>{' '}
                            The Service is designed to provide booking,
                            invoicing, communication and project management
                            tools for and between those who use the Service to
                            help manage their business
                            (&ldquo;Businesses&rdquo;), their clients and
                            related parties (&ldquo;Clients&rdquo;), and the
                            Clients&rsquo; various other vendors
                            (&ldquo;Vendors&rdquo;). In some instances, a
                            Business may qualify as the Client of a Vendor.
                            HoneyBook may connect Clients to Businesses and/or
                            Vendors for the purpose of purchasing goods and/or
                            services. Businesses may use the Service to find and
                            book Vendors, manage their Clients, and send
                            invoices. Clients may use the service to find, hire
                            and work with various Businesses and Vendors.
                            Vendors and Businesses may use the Service to
                            promote and provide their goods and services to
                            Clients.{' '}
                        </p>

                        <p>
                            <b className="terms-marker">
                                Your Registration Obligations:
                            </b>{' '}
                            You may be required to register with HoneyBook in
                            order to access and use certain features of the
                            Service. If you choose to register for the Service,
                            you agree to provide and maintain true, accurate,
                            current and complete information about yourself as
                            prompted by the Service&rsquo;s registration form.
                            Registration data and certain other information
                            about you are governed by our Privacy Policy. If you
                            are under 13 years of age, you are not authorized to
                            use the Service, with or without registering. In
                            addition, if you are under 18 years old, you may use
                            the Service, with or without registering, only with
                            the approval of your parent or guardian.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Member Account, Password and Security:
                            </b>{' '}
                            You are responsible for maintaining the
                            confidentiality of your password and account, if
                            any, and are fully responsible for any and all
                            activities that occur under your password or
                            account. You agree to (a) immediately notify
                            HoneyBook of any unauthorized use of your password
                            or account or any other breach of security, and (b)
                            ensure that you exit from your account at the end of
                            each session when accessing the Service. HoneyBook
                            will not be liable for any loss or damage arising
                            from your failure to comply with this Section.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Modifications to Service:
                            </b>{' '}
                            HoneyBook reserves the right to modify or
                            discontinue, temporarily or permanently, the Service
                            (or any part thereof) with or without notice. You
                            agree that HoneyBook will not be liable to you or to
                            any third party for any modification, suspension or
                            discontinuance of the Service.{' '}
                        </p>

                        <p>
                            <b className="terms-marker">
                                General Practices Regarding Use and Storage:
                            </b>{' '}
                            You acknowledge that HoneyBook may establish general
                            practices and limits concerning use of the Service,
                            including without limitation the maximum period of
                            time that data or other content will be retained by
                            the Service and the maximum storage space that will
                            be allotted on HoneyBook&rsquo;s servers on your
                            behalf. You agree that HoneyBook has no
                            responsibility or liability for the deletion or
                            failure to store any data or other content
                            maintained or uploaded by the Service. You
                            acknowledge that HoneyBook reserves the right to
                            terminate accounts that are inactive for an extended
                            period of time. You further acknowledge that
                            HoneyBook reserves the right to change these general
                            practices and limits at any time, in its sole
                            discretion, with or without notice.{' '}
                        </p>

                        <p>
                            <b className="terms-marker">Mobile Services:</b> The
                            Service includes certain services that are available
                            via a mobile device, including (i) the ability to
                            upload content to the Service via a mobile device,
                            (ii) the ability to browse the Service and the Site
                            from a mobile device, (iii) the ability to access
                            certain features through an application downloaded
                            and installed on a mobile device, and (iv) the
                            ability to receive notifications, messages, and
                            updates on your mobile device (including via calls
                            and text messages) (collectively, the &ldquo;Mobile
                            Services&rdquo;). To the extent you access the
                            Service through a mobile device, your wireless
                            service carrier&rsquo;s standard charges, data rates
                            and other fees may apply. In addition, downloading,
                            installing, or using certain Mobile Services may be
                            prohibited or restricted by your carrier, and not
                            all Mobile Services may work with all carriers or
                            devices. By using the Services, you are also
                            consenting to be contacted by Honeybook or one of
                            our partners about our (or one of our partners’)
                            services and products by telephone (on a recorded
                            line), automated calling, pre-recorded calling, text
                            message, email, fax, telephone or any means, even if
                            you have opted in to the National Do Not Call List,
                            any state equivalent Do Not Call List or the
                            internal Do Not Call List of any company. You do not
                            have to agree to receive such communications as a
                            condition of getting any services or products from
                            Honeybook. In the event you no longer want to
                            receive communications from Honeybook or one of its
                            partners you agree to notify us directly. In the
                            event you no longer want to receive communications
                            from Honeybook or one of its partners you agree to
                            notify us directly at{' '}
                            <a href="mailto:concierge@honeybook.com">
                                concierge@honeybook.com
                            </a>
                            .
                        </p>

                        <h2 className="terms-title">2. Conditions of Use</h2>

                        <p>
                            <b className="terms-marker">HoneyBook Platform:</b>{' '}
                            HoneyBook offers a platform that helps connect
                            Clients, Vendors and Businesses (&ldquo;HoneyBook
                            Platform&rdquo;). HoneyBook is not a party to any
                            agreement between Clients and Vendors, Vendors and
                            Businesses, or Clients and Businesses. All dealings
                            are solely between the respective parties and
                            HoneyBook makes no representations and warranties on
                            behalf of any Clients, Vendors, or Businesses
                            (including but not limited to the cost, quality, or
                            timeliness of any goods or services provided by any
                            Vendors or Businesses) and will have no liability
                            for any interactions between Clients, Vendors or
                            Businesses. HoneyBook is not responsible for any
                            contracts or proposals between Clients, Businesses
                            and Vendors (or any combination thereof)
                            (collectively, "Vendor Contracts") that you upload
                            to the Service other than making such Vendor
                            Contracts available for review and signature
                            (including e-signature) as authorized by you.
                            Notwithstanding the foregoing, HoneyBook serves as
                            the limited authorized agent of the Vendor and
                            Business for the purpose of accepting payments from
                            Clients on behalf of Vendors and Businesses and is
                            responsible for transmitting such payments to the
                            Vendor or Business, and each Vendor and Business
                            hereby appoints HoneyBook as the Vendor&rsquo;s or
                            Businesses&rsquo;s limited agent solely for the
                            purpose of collecting payments made by Clients on
                            behalf of the Vendor or Business. HoneyBook has no
                            control over and does not guarantee the existence,
                            quality, safety, or legality of any goods or
                            services advertised by Vendors or Businesses; the
                            truth or accuracy of any advertisements; the ability
                            of Vendors to sell goods or services; the ability of
                            Clients to pay for any goods or services; or that a
                            Vendor, Business or Client will actually complete a
                            transaction. HoneyBook does not warrant or guarantee
                            that any goods or services offered through the
                            Service will meet a Client&rsquo;s requirements.
                        </p>

                        <p>
                            No Tax or Other Professional Advice: You acknowledge
                            and agree that Honeybook does not provide legal,
                            financial, tax or other professional advice as part
                            of providing the Services, including with respect to
                            any estimated taxes calculated using Honeybook’s tax
                            calculator. No action should be taken based upon any
                            information obtained through the Services without
                            first seeking independent professional advice from
                            an accountant, attorney, financial advisor, or other
                            person who is appropriately licensed and/or
                            qualified in the applicable field. Without limiting
                            the foregoing, you (not Honeybook) are solely
                            responsible for (and you shall hold Honeybook
                            harmless with respect to): (i) compliance with any
                            and all applicable laws, rules and regulations, (ii)
                            determination of any and all amounts you may owe to
                            the government or other third parties and full
                            payment thereof (including, without limitation, all
                            applicable taxes, penalties, and interest), and
                            (iii) any use you may make of the Services to assist
                            you with the foregoing.
                        </p>

                        <p>
                            <b className="terms-marker">User Conduct:</b> You
                            are solely responsible for all code, video, images,
                            information, data, text, software, music, sound,
                            photographs, graphics, messages or other materials
                            ("content") that you upload, post, publish or
                            display (hereinafter, &ldquo;upload&rdquo;) or email
                            or otherwise use via the Service, whether as a
                            Client, Businesses or Vendor. The following are
                            examples of the kind of content and/or use that is
                            illegal or prohibited by HoneyBook. HoneyBook
                            reserves the right to investigate and take
                            appropriate legal action against anyone who, in
                            HoneyBook&rsquo;s sole discretion, violates this
                            provision, including without limitation, removing
                            the offending content from the Service, suspending
                            or terminating the account of such violators and
                            reporting you to the law enforcement authorities.
                            You agree to not use the Service to:
                        </p>

                        <ul className="terms-items">
                            <li>
                                email or otherwise upload any content that (i)
                                infringes any intellectual property or other
                                proprietary rights of any party; (ii) you do not
                                have a right to upload under any law or under
                                contractual or fiduciary relationships; (iii)
                                contains software viruses or any other computer
                                code, files or programs designed to interrupt,
                                destroy or limit the functionality of any
                                computer software or hardware or
                                telecommunications equipment; (iv) poses or
                                creates a privacy or security risk to any
                                person; (v) constitutes unsolicited or
                                unauthorized advertising, promotional materials,
                                commercial activities and/or sales, &ldquo;junk
                                mail,&rdquo; &ldquo;spam,&rdquo; &ldquo;chain
                                letters,&rdquo; &ldquo;pyramid schemes,&rdquo;
                                &ldquo;contests,&rdquo;
                                &ldquo;sweepstakes,&rdquo; or any other form of
                                solicitation; (vi) is unlawful, harmful,
                                threatening, abusive, harassing, tortious,
                                excessively violent, defamatory, vulgar,
                                obscene, pornographic, libelous, invasive of
                                another&rsquo;s privacy, hateful racially,
                                ethnically or otherwise objectionable; or (vii)
                                in the sole judgment of HoneyBook, is
                                objectionable or which restricts or inhibits any
                                other person from using or enjoying the Service,
                                or which may expose HoneyBook or its users to
                                any harm or liability of any type;{' '}
                            </li>
                            <li>
                                {' '}
                                interfere with or disrupt the Service or servers
                                or networks connected to the Service, or disobey
                                any requirements, procedures, policies or
                                regulations of networks connected to the
                                Service; or
                            </li>

                            <li>
                                violate any applicable local, state, national or
                                international law, or any regulations having the
                                force of law;{' '}
                            </li>
                            <li>
                                impersonate any person or entity, or falsely
                                state or otherwise misrepresent your credentials
                                or your affiliation with a person or entity;{' '}
                            </li>
                            <li>
                                solicit personal information from anyone under
                                the age of 18;
                            </li>
                            <li>
                                harvest or collect email addresses or other
                                contact information of other users from the
                                Service by electronic or other means for the
                                purposes of sending unsolicited emails or other
                                unsolicited communications;{' '}
                            </li>
                            <li>
                                advertise or offer to sell or buy any goods or
                                services for any business purpose that is not
                                specifically authorized;{' '}
                            </li>
                            <li>
                                further or promote any criminal activity or
                                enterprise or provide instructional information
                                about illegal activities; or{' '}
                            </li>
                            <li>
                                obtain or attempt to access or otherwise obtain
                                any materials or information through any means
                                not intentionally made available or provided for
                                through the Service.
                            </li>
                        </ul>

                        <p>
                            To the extent permitted by applicable law, HoneyBook
                            takes no responsibility and assumes no liability for
                            any content or for any loss or damage resulting
                            therefrom, nor is HoneyBook liable for any mistakes,
                            defamation, slander, libel, omissions, falsehoods,
                            obscenity, pornography, or profanity you may
                            encounter when using the HoneyBook Services. Your
                            use of the HoneyBook Services is at your own risk.
                            In addition, these rules do not create any private
                            right of action on the part of any third party or
                            any reasonable expectation that the HoneyBook
                            Services will not contain any content that is
                            prohibited by such rules.
                        </p>

                        <p>
                            HoneyBook is not liable for any statements or
                            representations included in the content. HoneyBook
                            does not endorse any content, opinion,
                            recommendation, or advice expressed therein, and
                            HoneyBook expressly disclaims any and all liability
                            in connection with the content. To the fullest
                            extent permitted by applicable law, HoneyBook
                            reserves the right to remove, screen, or edit any
                            content posted or stored on the HoneyBook Services
                            at any time and without notice, including where such
                            content violates these Terms of Service or
                            applicable law, and you are solely responsible for
                            creating backup copies of and replacing any content
                            you post or store on the HoneyBook Services at your
                            sole cost and expense. Any use of the HoneyBook
                            Services in violation of the foregoing violates
                            these Terms of Service and may result in, among
                            other things, termination or suspension of your
                            rights to use the HoneyBook Services.
                        </p>

                        <p>
                            {' '}
                            <b className="terms-marker">Fees:</b> To the extent
                            the Service or any portion thereof is made available
                            for any fee, or you are a Client paying a Vendor or
                            Business for goods or services via the Service, you
                            will be required to provide HoneyBook information
                            regarding your credit card or other payment
                            instrument. You represent and warrant to HoneyBook
                            that such information is true and that you are
                            authorized to use the payment instrument. If you
                            have registered for the Services via a subscription
                            plan, you expressly acknowledge and agree that (a)
                            HoneyBook is authorized to charge your credit card
                            or other payment instrument in accordance with the
                            terms of your subscription plan for as long as your
                            subscription continues, and (b) your subscription is
                            continuous until you cancel it or HoneyBook suspends
                            or otherwise stops providing access to the site
                            and/or Services in accordance with these terms. You
                            will promptly update your account information with
                            any changes (for example, a change in your billing
                            address or credit card expiration date) that may
                            occur. As a Vendor, you may be required to select a
                            payment plan and you agree to pay a pre-selected
                            percentage of money you receive from purchases made
                            by Clients of your goods and services through the
                            Service, and you will pay HoneyBook a percentage of
                            each such transaction, as set forth in further
                            detail on the Service. If you dispute any charges by
                            HoneyBook you must let HoneyBook know within thirty
                            (30) days after the date that HoneyBook charges you.
                            If you dispute any charges by a Vendor, you must
                            contact the Vendor directly. As a Vendor, if you
                            agree to refund any fees to a Client, you are
                            responsible and will pay HoneyBook (and HoneyBook
                            may retain) any transaction costs associated with
                            such refund. We reserve the right to change
                            HoneyBook&rsquo;s prices. If HoneyBook does change
                            prices, HoneyBook will provide notice of the change
                            on the Site or in email to you, at HoneyBook&rsquo;s
                            option, at least 30 days before the change is to
                            take effect. If you have registered for the Services
                            via a subscription plan, your subscription plan may
                            be subject to fee adjustments, including automatic
                            fee increases, during your subscription term in
                            accordance with the terms of your subscription plan;
                            and where a fee adjustment applies to you, we will
                            charge or invoice you under the new price structure,
                            starting with the next billing period in the
                            subscription term, or otherwise in accordance with
                            the terms of your subscription plan. Your continued
                            use of the Service after the price change or fee
                            adjustments becomes effective constitutes your
                            agreement to pay the changed amount. You shall be
                            responsible for all taxes associated with the
                            Services other than U.S. taxes based on
                            HoneyBook&rsquo;s net income.
                        </p>

                        <p>
                            <b className="terms-marker">Chargebacks: </b>
                            To the extent you have received payment for goods or
                            services through the HoneyBook Platform
                            (&ldquo;Payee&rdquo;) from or on behalf of a Client,
                            Business or other party (&ldquo;Payor&rdquo;), the
                            amount of a transaction may be charged back or
                            reversed to Payee&rsquo;s payment account (a
                            &ldquo;Chargeback&rdquo;) if the transaction (a) is
                            disputed by the Payor, (b) is reversed for any
                            reason, (c) was not authorized or we have any reason
                            to believe that the transaction was not authorized,
                            or (d) is allegedly unlawful, suspicious, or in
                            violation of the terms of these Terms of Service.
                            Payee shall owe HoneyBook and will immediately pay
                            HoneyBook the amount of any Chargeback and any
                            associated fees, fines, or penalties assessed by any
                            processing financial institutions, or MasterCard,
                            Visa, American Express, Discover, and other payment
                            card networks, associations, or companies.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Authorization to Charge Payment Methods on File:{' '}
                            </b>
                            You, as Payee, authorize us to charge any payment
                            method we have on file for you in the amount of any
                            Chargeback; any other amount you owe us; any Losses
                            we may incur relating to your transactions, your use
                            of the Services, or your business; any obligations
                            you owe us, including under these Terms of Service
                            or any other agreement we have with you; or to fund
                            the Reserve. To execute such charges, you expressly
                            authorize us to (a) initiate individual or
                            reoccurring debit entries to any bank account we
                            have on file for you (e.g., ACH debits) and (b)
                            charge any credit or debit card account we have on
                            file for you. If any debit entry or charge is
                            rejected, you agree to immediately pay the amount
                            due directly to us and authorize us to initiate
                            other debit entries and charges at any time and from
                            time to time in any amount up to the amount due
                            until the amount due is paid in full. We are not
                            responsible for any amount you may incur from our
                            charges, including overdraft and overlimit fees.
                            Payment methods we have on file for you include
                            payment methods you identify to us and payment
                            methods we identify on our own. For purposes of this
                            and the next section, &ldquo;Losses&rdquo; include
                            Chargebacks, refunds, returns, shortfalls,
                            adjustments, settlements, losses, liabilities,
                            damages, fines, penalties, fees, costs and expenses.
                        </p>

                        <p>
                            <b className="terms-marker">Reserve: </b>
                            We may withhold funds from payments (including
                            payment processor payouts) to you, as Payee, and/or
                            designate an amount of funds that you must maintain
                            in a reserve account held by us
                            (&ldquo;Reserve&rdquo;) to pay any actual or
                            potential Losses we believe we may incur related to
                            your transactions, your use of the Services, your
                            business, or to secure the performance of your
                            obligations under any agreement between you and us,
                            including these Terms of Service. The Reserve will
                            be in an amount determined by us in our sole
                            discretion to cover actual or potential Losses we
                            may incur and current, past and future obligations
                            you may owe us. The Reserve may be raised, reduced
                            or removed at any time by us, in our sole
                            discretion. If you do not have sufficient funds in
                            your Reserve, we may fund the Reserve from any
                            payment method we have on file for you. You grant us
                            a security interest in and lien on any and all funds
                            held in the Reserve, and also authorize us to make
                            any withdrawals or debits from the Reserve, and
                            charge any payment method we have on file for you,
                            to cover any Losses, obligations and amounts you owe
                            us. You will execute any documents required by us to
                            perfect our security interest in any funds in the
                            Reserve or requested by us in connection with the
                            Reserve.
                        </p>

                        <p>
                            <b className="terms-marker">Setoff: </b>
                            In the event that there is an outstanding amount you
                            owe us, we may set off such amount from any payments
                            that would otherwise be made to you.
                        </p>

                        <p>
                            <b className="terms-marker">Power of Attorney: </b>
                            You appoint us and our designees your true and
                            lawful attorney in fact, with full power to take any
                            action in your name and place relating to any
                            amounts you owe us under any agreement you have with
                            us, including these Terms of Service, that we deem
                            advisable and consistent with the terms of such
                            agreement. You will timely execute and deliver to us
                            any power of attorney instrument we may require
                            evidencing our authority and power under this
                            section.
                        </p>

                        <p>
                            <b className="terms-marker">Due Diligence: </b>
                            You, as a Business or Vendor, agree to provide us
                            with any financial statements, balance sheets,
                            statements of income, bank and credit card account
                            information, and any other financial or business
                            information we may request within two (2) business
                            days of our request. Failure to provide this
                            information timely is a breach of these Terms of
                            Service and may result in establishing a Reserve or
                            raising the amount of the Reserve.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Special Notice for International Use; Export
                                Controls:{' '}
                            </b>
                            Software (defined below) available in connection
                            with the Service and the transmission of applicable
                            data, if any, is subject to United States export
                            controls. No Software may be downloaded from the
                            Service or otherwise exported or re-exported in
                            violation of U.S. export laws. Downloading or using
                            the Software is at your sole risk. Recognizing the
                            global nature of the Internet, you agree to comply
                            with all local rules and laws regarding your use of
                            the Service, including as it concerns online conduct
                            and acceptable content.
                        </p>

                        <p>
                            <b className="terms-marker">Commercial Use: </b>
                            Unless otherwise expressly authorized herein or in
                            the Service, you agree not to display, distribute,
                            license, perform, publish, reproduce, duplicate,
                            copy, create derivative works from, modify, sell,
                            resell, exploit, transfer or upload for any
                            commercial purposes, any portion of the Service, use
                            of the Service, or access to the Service.
                        </p>

                        <h2 className="terms-title">
                            3. Third Party Distribution Channels
                        </h2>

                        <p>
                            HoneyBook offers Software applications that may be
                            made available through the Apple App Store, Android
                            Marketplace or other distribution channels
                            (“Distribution Channels”). If you obtain such
                            Software through a Distribution Channel, you may be
                            subject to additional terms of the Distribution
                            Channel. These Terms of Service are between you and
                            us only, and not with the Distribution Channel. To
                            the extent that you utilize any other third party
                            products and services in connection with your use of
                            our Services, you agree to comply with all
                            applicable terms of any agreement for such third
                            party products and services.
                        </p>

                        <p>
                            With respect to Software that is made available for
                            your use in connection with an Apple-branded product
                            (such Software, &ldquo;Apple- Enabled
                            Software&rdquo;), in addition to the other terms and
                            conditions set forth in these Terms of Service, the
                            following terms and conditions apply:
                        </p>

                        <ul className="terms-items">
                            <li>
                                HoneyBook and you acknowledge that these Terms
                                of Service are concluded between HoneyBook and
                                you only, and not with Apple, and that as
                                between HoneyBook and Apple, HoneyBook, not
                                Apple, is solely responsible for the
                                Apple-Enabled Software and the content thereof.
                            </li>
                            <li>
                                You may not use the Apple-Enabled Software in
                                any manner that is in violation of or
                                inconsistent with the Usage Rules set forth for
                                Apple-Enabled Software in, or otherwise be in
                                conflict with, the App Store Terms of Service.{' '}
                            </li>
                            <li>
                                Your license to use the Apple-Enabled Software
                                is limited to a non-transferable license to use
                                the Apple-Enabled Software on an iOS Product
                                that you own or control, as permitted by the
                                Usage Rules set forth in the App Store Terms of
                                Service.
                            </li>
                            <li>
                                Apple has no obligation whatsoever to provide
                                any maintenance or support services with respect
                                to the Apple-Enabled Software.{' '}
                            </li>
                            <li>
                                Apple is not responsible for any product
                                warranties, whether express or implied by law.
                                In the event of any failure of the Apple-Enabled
                                Software to conform to any applicable warranty,
                                you may notify Apple, and Apple will refund the
                                purchase price for the Apple-Enabled Software to
                                you, if any; and, to the maximum extent
                                permitted by applicable law, Apple will have no
                                other warranty obligation whatsoever with
                                respect to the Apple-Enabled Software, or any
                                other claims, losses, liabilities, damages,
                                costs or expenses attributable to any failure to
                                conform to any warranty, which will be
                                HoneyBook&rsquo;s sole responsibility, to the
                                extent it cannot be disclaimed under applicable
                                law.
                            </li>
                            <li>
                                HoneyBook and you acknowledge that HoneyBook,
                                not Apple, is responsible for addressing any
                                claims of you or any third party relating to the
                                Apple-Enabled Software or your possession and/or
                                use of that Apple-Enabled Software, including,
                                but not limited to: (i) product liability
                                claims; (ii) any claim that the Apple-Enabled
                                Software fails to conform to any applicable
                                legal or regulatory requirement; and (iii)
                                claims arising under consumer protection or
                                similar legislation.{' '}
                            </li>
                            <li>
                                In the event of any third party claim that the
                                Apple-Enabled Software or the end-user&rsquo;s
                                possession and use of that Apple-Enabled
                                Software infringes that third party&rsquo;s
                                intellectual property rights, as between
                                HoneyBook and Apple, HoneyBook, not Apple, will
                                be solely responsible for the investigation,
                                defense, settlement and discharge of any such
                                intellectual property infringement claim.
                            </li>
                            <li>
                                You represent and warrant that (i) you are not
                                located in a country that is subject to a U.S.
                                Government embargo, or that has been designated
                                by the U.S. Government as a &ldquo;terrorist
                                supporting&rdquo; country; and (ii) you are not
                                listed on any U.S. Government list of prohibited
                                or restricted parties.{' '}
                            </li>
                            <li>
                                If you have any questions, complaints or claims
                                with respect to the Apple-Enabled Software, they
                                should be directed to HoneyBook as follows:{' '}
                            </li>
                            <li>
                                <ul>
                                    <li>
                                        <a
                                            href="mailto:concierge@honeybook.com"
                                            className="link-act"
                                            target="_blank"
                                            title="HoneyBook support"
                                        >
                                            concierge@honeybook.com
                                        </a>
                                        <br />
                                        539 Bryant St, San Francisco, CA 94107
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <p>
                            HoneyBook and you acknowledge and agree that Apple,
                            and Apple&rsquo;s subsidiaries, are third party
                            beneficiaries of these Terms of Service with respect
                            to the Apple-Enabled Software, and that, upon your
                            acceptance of the terms and conditions of these
                            Terms of Service, Apple will have the right (and
                            will be deemed to have accepted the right) to
                            enforce these Terms of Service against you with
                            respect to the Apple-Enabled Software as a third
                            party beneficiary thereof.
                        </p>

                        <h2 className="terms-title">
                            4. Intellectual Property Rights
                        </h2>

                        <p>
                            <b className="terms-marker">
                                Service Content, Software and Trademarks:{' '}
                            </b>
                            You acknowledge and agree that the Service may
                            contain content or features (&ldquo;Service
                            Content&rdquo;) that are protected by copyright,
                            patent, trademark, trade secret or other proprietary
                            rights and laws. Except as expressly authorized by
                            HoneyBook, you agree not to modify, copy, frame,
                            scrape, rent, lease, loan, sell, distribute or
                            create derivative works based on the Service or the
                            Service Content, in whole or in part, except that
                            the foregoing does not apply to your own User
                            Content (as defined below) that you legally upload
                            to the Service. In connection with your use of the
                            Service you will not engage in or use any data
                            mining, robots, scraping or similar data gathering
                            or extraction methods. If you are blocked by
                            HoneyBook from accessing the Service (including by
                            blocking your IP address), you agree not to
                            implement any measures to circumvent such blocking
                            (e.g., by masking your IP address or using a proxy
                            IP address). Any use of the Service or the Service
                            Content other than as specifically authorized herein
                            is strictly prohibited. The technology and software
                            underlying the Service or distributed in connection
                            therewith are the property of HoneyBook, our
                            affiliates and our partners (the
                            &ldquo;Software&rdquo;). You agree not to copy,
                            modify, create a derivative work of, reverse
                            engineer, reverse assemble or otherwise attempt to
                            discover any source code, sell, assign, sublicense,
                            or otherwise transfer any right in the Software. Any
                            rights not expressly granted herein are reserved by
                            HoneyBook.
                        </p>

                        <p className="terms-text terms-padding-top">
                            The HoneyBook name and logos are trademarks and
                            service marks of HoneyBook (collectively the
                            &ldquo;HoneyBook Trademarks&rdquo;). Other
                            HoneyBook, product, and service names and logos used
                            and displayed via the Service may be trademarks or
                            service marks of their respective owners who may or
                            may not endorse or be affiliated with or connected
                            to HoneyBook. Nothing in this Terms of Service or
                            the Service should be construed as granting, by
                            implication, estoppel, or otherwise, any license or
                            right to use any of HoneyBook Trademarks displayed
                            on the Service, without our prior written permission
                            in each instance. All goodwill generated from the
                            use of HoneyBook Trademarks will inure to our
                            exclusive benefit.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Third Party Material:
                            </b>{' '}
                            Under no circumstances will HoneyBook be liable in
                            any way for any content or materials of any third
                            parties (including users), including, but not
                            limited to, for any errors or omissions in any
                            content, or for any loss or damage of any kind
                            incurred as a result of the use of any such content.
                            You acknowledge that HoneyBook does not pre-screen
                            content, but that HoneyBook and its designees will
                            have the right (but not the obligation) in their
                            sole discretion to refuse or remove any content that
                            is available via the Service. Without limiting the
                            foregoing, HoneyBook and its designees will have the
                            right to remove any content that violates these
                            Terms of Service or is deemed by HoneyBook, in its
                            sole discretion, to be otherwise objectionable. You
                            agree that you must evaluate, and bear all risks
                            associated with, the use of any content, including
                            any reliance on the accuracy, completeness, or
                            usefulness of such content.
                        </p>

                        <p>
                            <b className="terms-marker">
                                User Content Transmitted Through the Service:{' '}
                            </b>
                            With respect to the content or other materials you
                            upload through the Service or share with other users
                            or recipients, whether as a User, Vendor or Business
                            (collectively, &ldquo;User Content&rdquo;), you
                            represent and warrant that you own all right, title
                            and interest in and to such User Content, including,
                            without limitation, all copyrights and rights of
                            publicity contained therein. By uploading any User
                            Content you hereby grant and will grant HoneyBook
                            and its affiliated companies a nonexclusive,
                            worldwide, royalty free, fully paid up,
                            transferable, sublicensable, perpetual, irrevocable
                            license to copy, display, upload, perform,
                            distribute, store, modify and otherwise use your
                            User Content in connection with the operation of or
                            improvements to the Service in any form, medium or
                            technology now known or later developed.
                            Notwithstanding the foregoing, HoneyBook will not
                            disclose any Vendor Contracts that have been
                            uploaded to the Service to any third party without
                            your authorization. However, for clarity, you
                            acknowledge and agree that HoneyBook may collect,
                            analyze and use certain Vendor Contract information
                            on an aggregated, anonymous basis in order to
                            provide the Services.
                        </p>

                        <p>
                            However, for clarity, you acknowledge and agree that
                            HoneyBook may collect, analyze and use certain
                            Vendor Contract information on an aggregated,
                            anonymous basis in order to provide the Services.
                            You acknowledge and agree that any questions,
                            comments, suggestions, ideas, feedback or other
                            information about the Service
                            (&ldquo;Submissions&rdquo;), provided by you to
                            HoneyBook are non-confidential and HoneyBook will be
                            entitled to the unrestricted use and dissemination
                            of these Submissions for any purpose, commercial or
                            otherwise, without acknowledgment or compensation to
                            you.
                        </p>

                        <p>
                            You acknowledge and agree that HoneyBook may
                            preserve content and may also disclose content if
                            required to do so by law or in the good faith belief
                            that such preservation or disclosure is reasonably
                            necessary to: (a) comply with legal process,
                            applicable laws or government requests; (b) enforce
                            these Terms of Service; (c) respond to claims that
                            any content violates the rights of third parties; or
                            (d) protect the rights, property, or personal safety
                            of HoneyBook, its users and the public. You
                            understand that the technical processing and
                            transmission of the Service, including your content,
                            may involve (a) transmissions over various networks;
                            and (b) changes to conform and adapt to technical
                            requirements of connecting networks or devices.
                        </p>
                        <p>
                            <b className="terms-marker">Publicity: </b>You
                            acknowledge and agree that HoneyBook may use your
                            name and logo on HoneyBook&rsquo;s website and
                            marketing materials to identify your relationship
                            with HoneyBook.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Copyright Complaints:{' '}
                            </b>
                            HoneyBook respects the intellectual property of
                            others, and we ask our users to do the same. If you
                            believe that your work has been copied in a way that
                            constitutes copyright infringement, or that your
                            intellectual property rights have been otherwise
                            violated, you should notify HoneyBook of your
                            infringement claim in accordance with the procedure
                            set forth below.
                        </p>

                        <p>
                            HoneyBook will process and investigate notices of
                            alleged infringement and will take appropriate
                            actions under the Digital Millennium Copyright Act
                            ("DMCA") and other applicable intellectual property
                            laws with respect to any alleged or actual
                            infringement. A notification of claimed copyright
                            infringement should be emailed to HoneyBook&rsquo;s
                            Copyright Agent at{' '}
                            <a
                                href="mailto:copyright@honeybook.com"
                                className="link-act"
                                target="_blank"
                                title="HoneyBook support"
                            >
                                copyright@honeybook.com
                            </a>{' '}
                            (Subject line: &ldquo;DMCA Takedown Request&rdquo;).
                            You may also contact us by mail at:
                        </p>

                        <p>539 Bryant St, San Francisco, CA 94107.</p>

                        <p className="terms-padding-top terms-padding-bottom">
                            To be effective, the notification must be in writing
                            and contain the following information:
                        </p>

                        <ul className="terms-items">
                            <li>
                                an electronic or physical signature of the
                                person authorized to act on behalf of the owner
                                of the copyright or other intellectual property
                                interest;
                            </li>
                            <li>
                                a description of the copyrighted work or other
                                intellectual property that you claim has been
                                infringed;
                            </li>
                            <li>
                                a description of where the material that you
                                claim is infringing is located on the Service,
                                with enough detail that we may find it on the
                                Service;
                            </li>
                            <li>
                                your address, telephone number, and email
                                address;
                            </li>
                            <li>
                                a statement by you that you have a good faith
                                belief that the disputed use is not authorized
                                by the copyright or intellectual property owner,
                                its agent, or the law;
                            </li>
                            <li>
                                a statement by you, made under penalty of
                                perjury, that the above information in your
                                Notice is accurate and that you are the
                                copyright or intellectual property owner or
                                authorized to act on the copyright or
                                intellectual property owner&rsquo;s behalf.
                            </li>

                            <li>
                                <b className="terms-marker">Counter-Notice: </b>
                                If you believe that your User Content that was
                                removed (or to which access was disabled) is not
                                infringing, or that you have the authorization
                                from the copyright owner, the copyright
                                owner&rsquo;s agent, or pursuant to the law, to
                                upload and use the content in your User Content,
                                you may send a written counter-notice containing
                                the following information to the Copyright
                                Agent:
                            </li>
                            <li>your physical or electronic signature;</li>
                            <li>
                                identification of the content that has been
                                removed or to which access has been disabled and
                                the location at which the content appeared
                                before it was removed or disabled;
                            </li>
                            <li>
                                a statement that you have a good faith belief
                                that the content was removed or disabled as a
                                result of mistake or a misidentification of the
                                content; and
                            </li>
                            <li>
                                your name, address, telephone number, and email
                                address, a statement that you consent to the
                                jurisdiction of the federal court located within
                                Northern District of California and a statement
                                that you will accept service of process from the
                                person who provided notification of the alleged
                                infringement.
                            </li>

                            <li>
                                If a counter-notice is received by the Copyright
                                Agent, HoneyBook will send a copy of the
                                counter-notice to the original complaining party
                                informing that person that it may replace the
                                removed content or cease disabling it in 10
                                business days. Unless the copyright owner files
                                an action seeking a court order against the
                                content provider, member or user, the removed
                                content may be replaced, or access to it
                                restored, in 10 to 14 business days or more
                                after receipt of the counter-notice, at our sole
                                discretion.
                            </li>
                        </ul>

                        <p>
                            <b className="terms-marker">
                                Repeat Infringer Policy:
                            </b>{' '}
                            In accordance with the DMCA and other applicable
                            law, HoneyBook has adopted a policy of terminating,
                            in appropriate circumstances and at HoneyBook's sole
                            discretion, users who are deemed to be repeat
                            infringers. HoneyBook may also at its sole
                            discretion limit access to the Service and/or
                            terminate the memberships of any users who infringe
                            any intellectual property rights of others, whether
                            or not there is any repeat infringement. Third Party
                            Websites
                        </p>

                        <h2 className="terms-title">5. Third Party Websites</h2>

                        <p>
                            The Service may provide, or third parties may
                            provide, links or other access to other sites and
                            resources on the Internet. HoneyBook has no control
                            over such sites and resources and HoneyBook is not
                            responsible for and does not endorse such sites and
                            resources. You further acknowledge and agree that
                            HoneyBook will not be responsible or liable,
                            directly or indirectly, for any damage or loss
                            caused or alleged to be caused by or in connection
                            with use of or reliance on any content, events,
                            goods or services available on or through any such
                            site or resource. Any dealings you have with third
                            parties found while using the Service are between
                            you and the third party, and you agree that
                            HoneyBook is not liable for any loss or claim that
                            you may have against any such third party.
                        </p>

                        <h2 className="terms-title">
                            6. Social Networking Services
                        </h2>

                        <p>
                            You may enable or log in to the Service via various
                            online third party services, such as social media
                            and social networking services like Facebook or
                            Twitter (&ldquo;Social Networking Services&rdquo;).
                            By logging in or directly integrating these Social
                            Networking Services into the Service, we make your
                            online experiences richer and more personalized. To
                            take advantage of this feature and capabilities, we
                            may ask you to authenticate, register for or log
                            into Social Networking Services on the websites of
                            their respective providers. As part of such
                            integration, the Social Networking Services will
                            provide us with access to certain information that
                            you have provided to such Social Networking
                            Services, and we will use, store and disclose such
                            information in accordance with our Privacy Policy.
                            For more information about the implications of
                            activating these Social Networking Services and
                            HoneyBook&rsquo;s use, storage and disclosure of
                            information related to you and your use of such
                            services within HoneyBook (including your friend
                            lists and the like), please see our Privacy Policy
                            at{' '}
                            <a href="/terms/privacy">
                                https://www.HoneyBook.com/terms/privacy
                            </a>
                            . However, please remember that the manner in which
                            Social Networking Services use, store and disclose
                            your information is governed solely by the policies
                            of such third parties, and HoneyBook shall have no
                            liability or responsibility for the privacy
                            practices or other actions of any third party site
                            or service that may be enabled within the Service.
                        </p>

                        <p>
                            In addition, HoneyBook is not responsible for the
                            accuracy, availability or reliability of any
                            information, content, goods, data, opinions, advice
                            or statements made available in connection with
                            Social Networking Services. As such, HoneyBook is
                            not liable for any damage or loss caused or alleged
                            to be caused by or in connection with use of or
                            reliance on any such Social Networking Services.
                            HoneyBook enables these features merely as a
                            convenience and the integration or inclusion of such
                            features does not imply an endorsement or
                            recommendation.
                        </p>

                        <h2 className="terms-title">
                            7. Indemnity and Release
                        </h2>

                        <p>
                            You agree to release, indemnify and hold HoneyBook
                            and its affiliates and their officers, employees,
                            directors and agent (collectively, "Indemnitees")
                            harmless from any and all losses, damages, expenses,
                            including reasonable attorneys&rsquo; fees, rights,
                            claims, actions of any kind and injury (including
                            death) arising out of or relating to your use of the
                            Service, any User Content, your connection to the
                            Service, your violation of these Terms of Service or
                            your violation of any rights of another.
                            Notwithstanding the foregoing, you will have no
                            obligation to indemnify or hold harmless any
                            Indemnitee from or against any liability, losses,
                            damages or expenses incurred as a result of any
                            action or inaction of such Indemnitee. If you are a
                            California resident, you waive California Civil Code
                            Section 1542, which says: "A general release does
                            not extend to claims which the creditor does not
                            know or suspect to exist in his favor at the time of
                            executing the release, which if known by him must
                            have materially affected his settlement with the
                            debtor." If you are a resident of another
                            jurisdiction, you waive any comparable statute or
                            doctrine.
                        </p>

                        <h2 className="terms-title">
                            8. Disclaimer of Warranties
                        </h2>

                        <p>
                            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE
                            SERVICE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND
                            &ldquo;AS AVAILABLE&rdquo; BASIS. HONEYBOOK
                            EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                            WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
                            BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            TITLE AND NON-INFRINGEMENT.
                        </p>

                        <p>
                            HONEYBOOK MAKES NO WARRANTY THAT (I) THE SERVICE
                            WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL
                            BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
                            (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE
                            OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV)
                            THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION,
                            OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
                            THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
                        </p>

                        <h2 className="terms-title">
                            9. Limitation of Liability
                        </h2>

                        <p>
                            YOU EXPRESSLY UNDERSTAND AND AGREE THAT HONEYBOOK
                            WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                            SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR
                            DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT
                            LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA
                            OR OTHER INTANGIBLE LOSSES (EVEN IF HONEYBOOK HAS
                            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                            WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                            LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE
                            OR THE INABILITY TO USE THE SERVICE; (II) THE COST
                            OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                            RESULTING FROM ANY GOODS, DATA, INFORMATION OR
                            SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED
                            OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
                            SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION
                            OF YOUR TRANSMISSIONS OR DATA; (IV) ANY ACTS,
                            OMISSIONS, STATEMENTS OR OTHER CONDUCT OF ANY
                            CLIENTS, VENDORS, BUSINESSES OR OTHER THIRD PARTIES
                            ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO
                            THE SERVICE. IN NO EVENT WILL HONEYBOOK&rsquo;S
                            TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR
                            CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID
                            HONEYBOOK IN THE LAST SIX (6) MONTHS, OR, IF
                            GREATER, ONE HUNDRED DOLLARS ($100).
                        </p>

                        <p>
                            SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR
                            EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
                            EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                            CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
                            ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO
                            YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU
                            ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR
                            WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE
                            REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
                        </p>

                        <p>
                            IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING
                            SECTIONS TITLED &ldquo;DISCLAIMER OF
                            WARRANTIES&rdquo; AND &ldquo;LIMITATION OF
                            LIABILITY&rdquo; ARE INTENDED TO BE ONLY AS BROAD AS
                            IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW
                            JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO
                            BE INVALID UNDER THE LAWS OF THE STATE OF NEW
                            JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT
                            AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE
                            APPLICABLE SECTIONS.
                        </p>

                        <h2 className="terms-title">
                            10. Dispute Resolution By Binding Arbitration
                        </h2>
                        <p className="uppercase">
                            PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS
                            YOUR RIGHTS.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Agreement to Arbitrate:{' '}
                            </b>
                            This Section &ldquo;Dispute Resolution By Binding
                            Arbitration&rdquo; is referred to in this Terms of
                            Service as the &ldquo;Arbitration Agreement.&rdquo;
                            You agree that any and all disputes or claims that
                            have arisen or may arise between you and Company,
                            whether arising out of or relating to this Terms of
                            Service (including any alleged breach thereof), the
                            Services, any advertising, any aspect of the
                            relationship or transactions between us, shall be
                            resolved exclusively through final and binding
                            arbitration, rather than a court, in accordance with
                            the terms of this Arbitration Agreement, except that
                            you may assert individual claims in small claims
                            court, if your claims qualify. Further, this
                            Arbitration Agreement does not preclude you from
                            bringing issues to the attention of federal, state,
                            or local agencies, and such agencies can, if the law
                            allows, seek relief against us on your behalf. You
                            agree that, by entering into this Terms of Service,
                            you and Company are each waiving the right to a
                            trial by jury or to participate in a class action.
                            Your rights will be determined by a neutral
                            arbitrator, not a judge or jury. The Federal
                            Arbitration Act governs the interpretation and
                            enforcement of this Arbitration Agreement.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Prohibition of Class and Representative Actions
                                and Non-Individualized Relief:{' '}
                            </b>
                            YOU AND COMPANY AGREE THAT EACH OF US MAY BRING
                            CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS
                            AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                            PURPORTED CLASS OR REPRESENTATIVE ACTION OR
                            PROCEEDING. UNLESS BOTH YOU AND COMPANY AGREE
                            OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR
                            JOIN MORE THAN ONE PERSON&rsquo;S OR PARTY&rsquo;S
                            CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM
                            OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
                            PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
                            (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY
                            RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
                            SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
                            PROVIDE RELIEF NECESSITATED BY THAT PARTY&rsquo;S
                            INDIVIDUAL CLAIM(S),{' '}
                            <b>
                                EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE
                                ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF
                                UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR
                                THE ENFORCEABILITY OF THIS PROVISION.
                            </b>
                        </p>

                        <p>
                            <b className="terms-marker">
                                Pre-Arbitration Dispute Resolution:{' '}
                            </b>
                            Company is always interested in resolving disputes
                            amicably and efficiently, and most customer concerns
                            can be resolved quickly and to the customer&rsquo;s
                            satisfaction by emailing customer support at
                            concierge@honeybook.com. If such efforts prove
                            unsuccessful, a party who intends to seek
                            arbitration must first send to the other, by
                            certified mail, a written Notice of Dispute
                            (&ldquo;Notice&rdquo;). The Notice to Company should
                            be sent to 539 Bryant Street, Suite 200, San
                            Francisco, CA 94107 (&ldquo;Notice Address&rdquo;).
                            The Notice must (i) describe the nature and basis of
                            the claim or dispute and (ii) set forth the specific
                            relief sought. If Company and you do not resolve the
                            claim within sixty (60) calendar days after the
                            Notice is received, you or Company may commence an
                            arbitration proceeding. During the arbitration, the
                            amount of any settlement offer made by Company or
                            you shall not be disclosed to the arbitrator until
                            after the arbitrator determines the amount, if any,
                            to which you or Company is entitled.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Arbitration Procedures:{' '}
                            </b>
                            Arbitration will be conducted by a neutral
                            arbitrator in accordance with the American
                            Arbitration Association&rsquo;s (&ldquo;AAA&rdquo;)
                            rules and procedures, including the AAA&rsquo;s
                            Supplementary Procedures for Consumer-Related
                            Disputes (collectively, the &ldquo;AAA
                            Rules&rdquo;), as modified by this Arbitration
                            Agreement. For information on the AAA, please visit
                            its website, http://www.adr.org. Information about
                            the AAA Rules and fees for consumer disputes can be
                            found at the AAA&rsquo;s consumer arbitration page,
                            http://www.adr.org/consumer_arbitration. If there is
                            any inconsistency between any term of the AAA Rules
                            and any term of this Arbitration Agreement, the
                            applicable terms of this Arbitration Agreement will
                            control unless the arbitrator determines that the
                            application of the inconsistent Arbitration
                            Agreement terms would not result in a fundamentally
                            fair arbitration. The arbitrator must also follow
                            the provisions of these Terms of Service as a court
                            would. All issues are for the arbitrator to decide,
                            including, but not limited to, issues relating to
                            the scope, enforceability, and arbitrability of this
                            Arbitration Agreement. Although arbitration
                            proceedings are usually simpler and more streamlined
                            than trials and other judicial proceedings, the
                            arbitrator can award the same damages and relief on
                            an individual basis that a court can award to an
                            individual under the Terms of Service and applicable
                            law. Decisions by the arbitrator are enforceable in
                            court and may be overturned by a court only for very
                            limited reasons.
                            <br />
                            Unless Company and you agree otherwise, any
                            arbitration hearings will take place in a reasonably
                            convenient location for both parties with due
                            consideration of their ability to travel and other
                            pertinent circumstances. If the parties are unable
                            to agree on a location, the determination shall be
                            made by AAA. If your claim is for $10,000 or less,
                            Company agrees that you may choose whether the
                            arbitration will be conducted solely on the basis of
                            documents submitted to the arbitrator, through a
                            telephonic hearing, or by an in-person hearing as
                            established by the AAA Rules. If your claim exceeds
                            $10,000, the right to a hearing will be determined
                            by the AAA Rules. Regardless of the manner in which
                            the arbitration is conducted, the arbitrator shall
                            issue a reasoned written decision sufficient to
                            explain the essential findings and conclusions on
                            which the award is based.
                        </p>

                        <p>
                            <b className="terms-marker">
                                Costs of Arbitration:{' '}
                            </b>
                            Payment of all filing, administration, and
                            arbitrator fees (collectively, the "Arbitration
                            Fees") will be governed by the AAA Rules, unless
                            otherwise provided in this Arbitration Agreement. If
                            the value of the relief sought is $75,000 or less,
                            at your request, Company will pay all Arbitration
                            Fees. If the value of relief sought is more than
                            $75,000 and you are able to demonstrate to the
                            arbitrator that you are economically unable to pay
                            your portion of the Arbitration Fees or if the
                            arbitrator otherwise determines for any reason that
                            you should not be required to pay your portion of
                            the Arbitration Fees, Company will pay your portion
                            of such fees. In addition, if you demonstrate to the
                            arbitrator that the costs of arbitration will be
                            prohibitive as compared to the costs of litigation,
                            Company will pay as much of the Arbitration Fees as
                            the arbitrator deems necessary to prevent the
                            arbitration from being cost-prohibitive. Any payment
                            of attorneys&rsquo; fees will be governed by the AAA
                            Rules.
                        </p>

                        <p>
                            <b className="terms-marker">Confidentiality: </b>
                            All aspects of the arbitration proceeding, and any
                            ruling, decision, or award by the arbitrator, will
                            be strictly confidential for the benefit of all
                            parties.
                        </p>

                        <p>
                            <b className="terms-marker">Severability: </b>
                            If a court or the arbitrator decides that any term
                            or provision of this Arbitration Agreement (other
                            than the subsection above titled &ldquo;Prohibition
                            of Class and Representative Actions and
                            Non-Individualized Relief&rdquo;) is invalid or
                            unenforceable, the parties agree to replace such
                            term or provision with a term or provision that is
                            valid and enforceable and that comes closest to
                            expressing the intention of the invalid or
                            unenforceable term or provision, and this
                            Arbitration Agreement shall be enforceable as so
                            modified. If a court or the arbitrator decides that
                            any of the provisions of the subsection above titled
                            &ldquo;Prohibition of Class and Representative
                            Actions and Non-Individualized Relief&rdquo; are
                            invalid or unenforceable, then the entirety of this
                            Arbitration Agreement shall be null and void, unless
                            such provisions are deemed to be invalid or
                            unenforceable solely with respect to claims for
                            public injunctive relief. The remainder of the Terms
                            of Service will continue to apply.
                        </p>

                        <p>
                            <b className="terms-marker">Future Changes: </b>
                            Notwithstanding any provision in this Terms of
                            Service to the contrary, Company agrees that if it
                            makes any future change to this Arbitration
                            Agreement (other than a change to the Notice
                            Address) while you are a user of the Services, you
                            may reject any such change by sending Company
                            written notice within thirty (30) calendar days of
                            the change to the Notice Address provided above. By
                            rejecting any future change, you are agreeing that
                            you will arbitrate any dispute between us in
                            accordance with the language of this Arbitration as
                            of the date you first accepted these Terms of
                            Service (or accepted any subsequent changes to these
                            Terms of Service).
                        </p>

                        <h2 className="terms-title">11. Termination</h2>

                        <p>
                            You agree that HoneyBook, in its sole discretion,
                            may suspend or terminate your account (or any part
                            thereof) or use of the Service and remove and
                            discard any content within the Service, for any
                            reason, including, without limitation, for lack of
                            use or if HoneyBook believes that you have violated
                            or acted inconsistently with the letter or spirit of
                            these Terms of Service. Any suspected fraudulent,
                            abusive or illegal activity that may be grounds for
                            termination of your use of Service, may be referred
                            to appropriate law enforcement authorities.
                            HoneyBook may also in its sole discretion and at any
                            time discontinue providing the Service, or any part
                            thereof, with or without notice. You agree that any
                            termination of your access to the Service under any
                            provision of this Terms of Service may be effected
                            without prior notice, and acknowledge and agree that
                            HoneyBook may immediately deactivate or delete your
                            account and all related information and files in
                            your account and/or bar any further access to such
                            files or the Service. Further, you agree that
                            HoneyBook will not be liable to you or any third
                            party for any termination of your access to the
                            Service.
                            <br />
                            If you have registered for the Service via a
                            subscription plan, you may cancel your subscription
                            at any time by sending an email to
                            concierge@honeybook.com notifying us of your intent
                            to cancel at least thirty (30) days prior to the end
                            of your then-current subscription term.
                        </p>

                        <h2 className="terms-title">12. User Disputes</h2>

                        <p>
                            You agree that you are solely responsible for your
                            interactions with any other user in connection with
                            the Service and HoneyBook will have no liability or
                            responsibility with respect thereto. HoneyBook
                            reserves the right, but has no obligation, to become
                            involved in any way with disputes between you and
                            any other user of the Service, including by giving
                            Clients access to their agreements with Businesses
                            and Vendors.
                        </p>

                        <h2 className="terms-title">13. General</h2>

                        <p>
                            These Terms of Service constitute the entire
                            agreement between you and HoneyBook and govern your
                            use of the Service, superseding any prior agreements
                            between you and HoneyBook with respect to the
                            Service. You also may be subject to additional terms
                            and conditions that may apply when you use affiliate
                            or third party services, third party content or
                            third party software. These Terms of Service will be
                            governed by the laws of the State of California
                            without regard to its conflict of law provisions.
                            With respect to any disputes or claims not subject
                            to arbitration, as set forth above, you and
                            HoneyBook agree to submit to the personal and
                            exclusive jurisdiction of the state and federal
                            courts located within San Francisco County,
                            California. The failure of HoneyBook to exercise or
                            enforce any right or provision of these Terms of
                            Service will not constitute a waiver of such right
                            or provision. If any provision of these Terms of
                            Service is found by a court of competent
                            jurisdiction to be invalid, the parties nevertheless
                            agree that the court should endeavor to give effect
                            to the parties&rsquo; intentions as reflected in the
                            provision, and the other provisions of these Terms
                            of Service remain in full force and effect. You
                            agree that regardless of any statute or law to the
                            contrary, any claim or cause of action arising out
                            of or related to use of the Service or these Terms
                            of Service must be filed within one (1) year after
                            such claim or cause of action arose or be forever
                            barred. A printed version of this agreement and of
                            any notice given in electronic form will be
                            admissible in judicial or administrative proceedings
                            based upon or relating to this agreement to the same
                            extent and subject to the same conditions as other
                            business documents and records originally generated
                            and maintained in printed form. You may not assign
                            this Terms of Service without the prior written
                            consent of HoneyBook, but HoneyBook may assign or
                            transfer this Terms of Service, in whole or in part,
                            without restriction. The section titles in these
                            Terms of Service are for convenience only and have
                            no legal or contractual effect. Notices to you may
                            be made via either email or regular mail. The
                            Service may also provide notices to you of changes
                            to these Terms of Service or other matters by
                            displaying notices or links to notices generally on
                            the Service.
                        </p>

                        <h2 className="terms-title">14. Your Privacy</h2>

                        <p>
                            At HoneyBook, we respect the privacy of our users.
                            For details please see our Privacy Policy. By using
                            the Service, you consent to our collection and use
                            of personal data as outlined therein.
                        </p>

                        <h2 className="terms-title">
                            15. Notice for California Users
                        </h2>

                        <p>
                            Under California Civil Code Section 1789.3, users of
                            the Service from California are entitled to the
                            following specific consumer rights notice: The
                            Complaint Assistance Unit of the Division of
                            Consumer Services of the California Department of
                            Consumer Affairs may be contacted in writing at 1625
                            North Market Blvd., Suite N 112, Sacramento, CA
                            95834, or by telephone at (916) 445-1254 or (800)
                            952-5210. You may contact us at HoneyBook, Inc.,
                            (415) 591-7768, 539 Bryant St, Suite 200, San
                            Francisco, CA 94107.
                        </p>

                        <h2 className="terms-title">
                            16. Questions? Concerns? Suggestions?
                        </h2>

                        <p>
                            Please contact us at{' '}
                            <a
                                href="mailto:concierge@honeybook.com"
                                className="link-act"
                                target="_blank"
                                title="HoneyBook support"
                            >
                                concierge@honeybook.com
                            </a>{' '}
                            to report any violations of these Terms of Service
                            or to pose any questions regarding this Terms of
                            Service or the Service.
                        </p>
                    </div>
                </div>
            </FeatureBlockContent>
        </FeatureBlockSection>
    );
};
/* eslint-enable */

export default TOSTabContent;
