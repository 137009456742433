import type { TPromoBannerTheme } from '../components/PromoBanner/PromoBanner';
import type { TPricingPlans } from '../types/util-types';

export enum Coupons {
    welcome20 = 'welcome20',
    welcome50 = 'welcome50',
    joinhb = 'joinhb',
    cyber23 = 'cyber23',
    hb2024save = 'hb2024save',
    start50 = 'start50',
    save50 = 'save50',
    join50 = 'join50',
    join30 = 'join30',
    nopromo = 'nopromo',
    adobe50 = 'adobe50',
    cyber24 = 'cyber24',
    jan25 = 'jan25',
    jumpstart = 'jumpstart'
}

export const DEFAULT_PROMO_CODE = Coupons.jumpstart;

interface IMapOfPromoCodesConfig {
    [key: string]: {
        discount: {
            monthly: {
                fixedPrice?: number;
                discountPercentage?: number;
                excludedFromDiscount?: string[];
            };
            annualMonthly: {
                fixedPrice?: number;
                discountPercentage?: number;
                excludedFromDiscount?: TPricingPlans[];
            };
        };
        bannerTheme: TPromoBannerTheme;
        tosLink?: string;
    };
}

export const MAP_OF_PROMO_CODES_CONFIG: IMapOfPromoCodesConfig = {
    [Coupons.nopromo]: {
        discount: {
            monthly: {
                discountPercentage: 0
            },
            annualMonthly: {
                discountPercentage: 0
            }
        },
        bannerTheme: 'light'
    },
    [Coupons.adobe50]: {
        discount: {
            monthly: {
                discountPercentage: 50
            },
            annualMonthly: {
                discountPercentage: 50
            }
        },
        bannerTheme: 'light',
        tosLink: '/lp/honeybook-adobe-terms-of-service'
    },
    [Coupons.jan25]: {
        discount: {
            monthly: {},
            annualMonthly: {
                discountPercentage: 25,
                excludedFromDiscount: ['starter']
            }
        },
        bannerTheme: 'purple'
    },
    [Coupons.jumpstart]: {
        discount: {
            monthly: {},
            annualMonthly: {}
        },
        bannerTheme: 'purple'
    }
};
