import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HBText from '../honeybook-ui/HBText/HBText';
import ArrowLeftIcon from '../../../src/svg/arrow-left-line.inline.svg';
import AnalyticsService, {
    AnalyticsEvents
} from '../../Services/AnalyticsService';
import EmailForm from '../EmailForm/EmailForm';
import ArrowIcon from '../../../src/svg/arrow-circle.inline.svg';
import ApiService from '../../Services/ApiService';
import HBLoader from '../honeybook-ui/HBLoader/HBLoader';
import UtilsService from '../../Services/UtilsService';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import getApiUrl from '../../env';

import './tax-calculator-results.scss';

export const TAX_TOOL_RESULT_CTA_CLASS_NAME = 'email-form__cta-button';

interface Props {
    onReturn: () => void;
    calculationData: Record<string, any>;
}

const TaxCalculatorResults = ({
    onReturn,
    calculationData
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoint();

    const [isLoadingComplete, setIsloadingComplete] = useState(false);

    const resultsSectionId = 'tax-calculator-results';

    useEffect(() => {
        setTimeout(() => setIsloadingComplete(true), 2000);

        if (!breakpoints.mediumUp) {
            UtilsService.scrollToElementId(resultsSectionId, 20);
        }
    }, []);

    const taxCalculatorApi = (formattedData: Record<string, any>) => {
        const apiUrl = `${getApiUrl()}/api/v2/tools/tax_calculator`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formattedData)
        };

        return ApiService._apiFetch<void>(apiUrl, requestOptions);
    };

    const redirectToSignup = () => {
        const elCtaButton: HTMLButtonElement = document.querySelector(
            `.${TAX_TOOL_RESULT_CTA_CLASS_NAME} button`
        )!;
        if (!!elCtaButton) {
            elCtaButton.click();
        }
    };

    const submitEmail = async (email, isChecked) => {
        AnalyticsService.trackClick(
            AnalyticsEvents.tax_calculator_submit_email,
            { email }
        );

        AnalyticsService.reportIterableEvent(
            'lead_created',
            {
                email: email,
                lead_type: 'tax_tool',
                company_type: calculationData.businessType,
                calculationData,
                accept_getting_emails: calculationData.isChecked
            },
            { email }
        );

        await taxCalculatorApi({
            email,
            accept_getting_emails: isChecked,
            yearly_business_expenses: calculationData.yearlyBusinessExpenses,
            final_taxes: calculationData.totalTax,
            state: calculationData.state,
            income_w2: calculationData.incomeW2,
            income1099: calculationData.income1099,
            SETaxableIncome: calculationData.SETaxableIncome,
            SETax: calculationData.SETax,
            SETaxDeduction: calculationData.SETaxDeduction,
            standardDeduction: calculationData.standardDeduction,
            adjustedGrossIncome: calculationData.adjustedGrossIncome,
            fedIncomeTax: calculationData.fedIncomeTax,
            stateIncomeTax: calculationData.stateIncomeTax,
            totalTax: calculationData.totalTax
        });

        redirectToSignup();
    };

    const loadingContent = (
        <div className="tax-calculator-results__loading">
            <HBLoader />
            <HBText tag="p" type="body-s">
                {t('taxCalculator.calculatorResults.loading')}
            </HBText>
        </div>
    );

    const formattedResult = (
        <HBText
            customClass="tax-calculator-results__result"
            tag="h3"
            type="title-m"
        >{`$${Math.ceil(calculationData.totalTax)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</HBText>
    );

    const tips = ['expense', 'integrate', 'empower'];

    return (
        <div id={resultsSectionId} className="tax-calculator-results">
            <div className="tax-calculator-results__result-container">
                <div
                    className="tax-calculator-results__back-button"
                    onClick={onReturn}
                >
                    <ArrowLeftIcon />
                </div>

                <HBText
                    customClass="tax-calculator-results__title"
                    tag="p"
                    type="body-s"
                >
                    {t('taxCalculator.calculatorResults.title')}
                </HBText>

                <div className="tax-calculator-results__result-text-container">
                    {isLoadingComplete ? formattedResult : loadingContent}
                </div>

                <HBText
                    customClass="tax-calculator-results__description"
                    tag="p"
                    type="body-s"
                >
                    {t('taxCalculator.calculatorResults.enterEmail')}
                </HBText>

                <EmailForm
                    onFormSubmit={submitEmail}
                    buttonText={t('taxCalculator.calculatorResults.ctaText')}
                />
            </div>
            <div className="tax-calculator-results__tips">
                <HBText tag="h4" type="subtitle-l">
                    {t('taxCalculator.calculatorResults.didYouKnow.title')}
                </HBText>

                <div className="tax-calculator-results__list">
                    {tips.map(tip => (
                        <div
                            key={tip}
                            className="tax-calculator-results__list-row"
                        >
                            <ArrowIcon />
                            <HBText
                                customClass="tax-calculator-results__list-item"
                                tag="p"
                                type="body-s"
                            >
                                {t(
                                    `taxCalculator.calculatorResults.didYouKnow.${tip}`
                                )}
                            </HBText>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TaxCalculatorResults;
