"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testsConfig = exports.AB_TESTS_CONFIG = void 0;
exports.AB_TESTS_CONFIG = {
    test_manual_exposure: {
        variants: {
            control: { ab_test_variation: 'control' },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/']
    },
    paid_webflow: {
        variants: {
            control: { ab_test_variation: 'control' },
            webflow: {
                ab_test_variation: 'webflow'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/marketing-consultant-software'],
        webflowPath: '/p/marketing-consultant-software'
    },
    adobe_abc_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            adobeB: {
                ab_test_variation: 'adobeB'
            },
            adobeC: {
                ab_test_variation: 'adobeC'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/adobe']
    },
    referral_recipient_experience: {
        variants: {
            control: { ab_test_variation: 'control' },
            recipient: {
                ab_test_variation: 'recipient',
                redirectPath: '/signup'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false,
        pages: ['/invites']
    },
    client_relationships_hero_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/client-relationships']
    },
    cta_text_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            test: {
                ab_test_variation: 'test'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: [
            '/online-contract',
            '/get-document-signing',
            '/proposal-software',
            '/online-invoices',
            '/industry-venue-managers',
            '/industry-caterers',
            '/industry-event-planners',
            '/meeting-scheduler',
            '/contracts-for-event-and-wedding-planners',
            '/invoice-template',
            '/online-payment-software'
        ]
    },
    new_pricing_content: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            newPricing: { ab_test_variation: 'newPricing' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true,
        pages: ['/pricing']
    },
    jan25_promo: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            jan25: { ab_test_variation: 'jan25' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: false
    },
    teams_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            teams: { ab_test_variation: 'teams' }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true
    },
    education_test: {
        variants: {
            control: {
                ab_test_variation: 'control'
            },
            education: {
                ab_test_variation: 'education'
            }
        },
        controlVariantName: 'control',
        shouldAffectBuild: true
    }
};
exports.testsConfig = {
    ...exports.AB_TESTS_CONFIG
};
