import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import IntroducingAiBlock from '../components/FeatureBlocks/IntoducingAiBlock/IntroducingAiBlock';
import FeatureValuePropsBlockNew from '../components/FeatureBlocks/FeatureValuePropsBlock/FeatureValuePropsBlockNew';
import OverlapCarouselBlock from '../components/FeatureBlocks/OverlapCarouselBlock/OverlapCarouselBlock';
import FeatureChecklistHeroNew from '../components/Hero/FeatureChecklistHero/FeatureChecklistHero';
import TestimonialCarouselCardBlock from '../components/FeatureBlocks/TestimonialCarouselCardBlock/TestimonialCarouselCardBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import { TAdditionalDataForClickStartTrialModules } from '../types/util-types';
import RotatingTitleBlock from '../components/FeatureBlocks/RotatingTitleBlock/RotatingTitleBlock';
import verticalPaidStore from '../stores/verticalPaidStore';
import { usePromoCode } from '../hooks/usePromoCode';

import '../styles/paid-marketing.scss';

const ogTags = {
    url: 'https://www.honeybook.com/getstarted',
    title: 'Client Management Software for Small Businesses',
    description: `Get clients faster with HoneyBook's all-in-one project, invoicing and payments management tool. Start your free 7-day trial today.`
};

const PaidMarketingPage = () => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();
    const { values } = verticalPaidStore['generic'];

    return (
        <div className={'paid-marketing'}>
            <Layout ogTags={ogTags} slimNav slimFooter mobileStickyCTA>
                <div className="feature-block__wrapper">
                    <FeatureChecklistHeroNew
                        module={
                            TAdditionalDataForClickStartTrialModules.CHECK_LIST_GET_STARTED_PAGE
                        }
                        title={t(`valuePropsHero.title`)}
                        increaseTitleFontSize
                    />

                    <RotatingTitleBlock />

                    <FeatureValuePropsBlockNew values={values} minimal />

                    <IntroducingAiBlock />

                    <TestimonialCarouselCardBlock isAltTheme />

                    <OverlapCarouselBlock />

                    <PromoBlockLeft
                        title={t(`${promoCode}.promoBlockLeft.title`)}
                        subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                            defaultValue: null
                        })}
                        promoCode={promoCode}
                        buttonStyle="primary--bright"
                        darkTheme
                        source="promo block left - dark"
                    />
                </div>
            </Layout>
        </div>
    );
};

export default PaidMarketingPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "paidMarketing", "checklistHero"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
