import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import SchedulerHero from '../components/Hero/SchedulerHero/SchedulerHero';
import BookMeetingsFasterBlock from '../components/FeatureBlocks/BookMeetingsFasterBlock/BookMeetingsFasterBlock';
import ItsEasyBlock from '../components/FeatureBlocks/ItsEasyBlock/ItsEasyBlock';
import BrandedExperienceBlock from '../components/FeatureBlocks/BrandedExperienceBlock/BrandedExperienceBlock';
import IncreaseChanceOfWinningBlock from '../components/FeatureBlocks/IncreaseChanceOfWinningBlock/IncreaseChanceOfWinningBlock';
import TabsBlock from '../components/FeatureBlocks/TabsBlock/TabsBlock';
import CheckboxTabContent from '../components/CheckboxTabContent/CheckboxTabContent';
import ImageTextElementsBlock from '../components/FeatureBlocks/ImageTextElementsBlock/ImageTextElementsBlock';
import FAQBlock from '../components/FeatureBlocks/FAQBlock/FAQBlock';
import WereFirstClassBlock from '../components/FeatureBlocks/WereFirstClassBlock/WereFirstClassBlock';

import { FeatureBlockDivider } from '../components/FeatureBlockElements';

import '../styles/meeting-scheduler.scss';

import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';

const analyticsCallback = title => {
    AnalyticsService.trackClick(AnalyticsEvents.blog_post, {
        source: title
    });
};

const schedulingNeverSeenBeforeItems = [
    {
        title: 'schedulerPage.neverSeenBeforeBlock.items.item1.title',
        text: 'schedulerPage.neverSeenBeforeBlock.items.item1.text',
        imagePath: 'marketing_site/scheduler-page/brand.svg',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'schedulerPage.neverSeenBeforeBlock.items.item2.title',
        text: 'schedulerPage.neverSeenBeforeBlock.items.item2.text',
        imagePath: 'marketing_site/scheduler-page/multiple-meetings.svg',
        mediaFlags: { forceImgTag: true }
    },
    {
        title: 'schedulerPage.neverSeenBeforeBlock.items.item3.title',
        text: 'schedulerPage.neverSeenBeforeBlock.items.item3.text',
        imagePath: 'marketing_site/scheduler-page/confirmations.svg',
        mediaFlags: { forceImgTag: true }
    }
];

const faqs = [
    {
        id: 'schedulerWhatIs',
        answer: '#TEXT# <a href="https://www.honeybook.com/" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a>'
    },
    {
        id: 'schedulingSetup',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3614050" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a>'
    },
    {
        id: 'schedulingSessionTypes',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/3444494-understanding-managing-your-honeybook-calendar" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'schedulinglinShare',
        answer: '#TEXT# <a href="https://www.honeybook.com/app/templates" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a> #TEXT# <a href="https://www.honeybook.com/app/scheduling" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a>'
    },
    {
        id: 'schedulingExternalCalendar',
        answer: '#TEXT# <a href="https://help.honeybook.com/en/articles/2209101-syncing-your-google-calendar-to-honeybook" target="__blank" rel="noopener noreferrer">#LINK_TEXT#</a> #TEXT#'
    },
    {
        id: 'schedulingCustomize'
    },
    {
        id: 'schedulingCompare'
    }
];

const ogTags = {
    url: 'https://www.honeybook.com/meeting-scheduler',
    title: 'Online Meeting Scheduler for Freelancers & Creatives',
    description: `1:1 Online Meeting Scheduler to book meetings 2x faster. Set Your availability and share your link with clients. It's really that easy. Free 7 day trial.`,
    image: 'https://res.cloudinary.com/honeybook/image/upload/v1589474929/marketing_site/vertical-lps/Scheduling_OG_C.png'
};

function MeetingSchedulerTemplate() {
    const { t } = useTranslation();

    const tabsContent = [
        {
            name: t('schedulerPage.tabsBlock.tab1.title'),
            id: 'meet',
            panel: (
                <CheckboxTabContent bulletsString="schedulerPage.tabsBlock.tab1.bullets" />
            )
        },
        {
            name: t('schedulerPage.tabsBlock.tab2.title'),
            id: 'work',
            panel: (
                <CheckboxTabContent bulletsString="schedulerPage.tabsBlock.tab2.bullets" />
            )
        },
        {
            name: t('schedulerPage.tabsBlock.tab3.title'),
            id: 'impress',
            panel: (
                <CheckboxTabContent bulletsString="schedulerPage.tabsBlock.tab3.bullets" />
            )
        }
    ];

    return (
        <div className="scheduler-page">
            <Layout ogTags={ogTags}>
                <SchedulerHero />

                <BookMeetingsFasterBlock />

                <ItsEasyBlock />

                <BrandedExperienceBlock />

                <IncreaseChanceOfWinningBlock />

                <TabsBlock
                    title={t('schedulerPage.tabsBlock.title')}
                    tabsContent={tabsContent}
                />

                <FeatureBlockDivider />

                <ImageTextElementsBlock
                    title={t('schedulerPage.neverSeenBeforeBlock.title')}
                    imageTextElements={schedulingNeverSeenBeforeItems}
                    imagePosition="center"
                />

                <FAQBlock
                    title={t(`schedulerPage.FAQsSection.title`)}
                    faqs={faqs}
                    displayContactLink
                />

                <WereFirstClassBlock />
            </Layout>
        </div>
    );
}
export default MeetingSchedulerTemplate;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "scheduler", "faqs"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
