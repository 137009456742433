/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { ReactNode, useEffect, memo } from 'react';
import UtilsService from '../../Services/UtilsService';
//  Layout.tsx should be imported first in every file that uses it (css ordering issues)
import '../../styles/_global.scss';
import './layout.scss';

import Footer from '../Footer/Footer';
import FooterSlim from '../../components/FooterSlim/FooterSlim';

import NavBar from '../NavBar/NavBar';

import OGTags from '../../Services/OGTagsService';

import PageLoadService from '../../Services/PageLoadService';

import NavBarTools from '../NavBar/NavBarTools';
import type { TOGTags } from '../../types/util-types';
import ABTestToolService from '../../Services/ABTestToolService';
import ABTestToolServiceV2 from '../../Services/ABTestToolService.v2';
import CloudflareBannerService from '../../Services/CloudflareBannerService';
import { ABTestFallbackVariants } from '../../ab-tests/ABTestFallbackVariants';
import MobileStickyCTA from '../../components/MobileStickyCTA/MobileStickyCTA';
import { useABTestContext } from '../../ab-tests/context/useABTestContext';

interface Props {
    ogTags: TOGTags;
    navBarTools?: boolean;
    lang?: 'en' | 'es'; // TODO: add more languages then required
    children?: ReactNode;
    slimNav?: boolean;
    noNav?: boolean;
    noFooter?: boolean;
    slimFooter?: boolean;
    noPromo?: boolean;
    customClass?: string;
    noCta?: boolean;
    mobileStickyCTA?: boolean;
}

function Layout({
    children,
    ogTags,
    navBarTools = false,
    lang,
    slimNav = false,
    noFooter = false,
    slimFooter = false,
    noPromo = false,
    noNav = false,
    customClass,
    noCta = false,
    mobileStickyCTA = false
}: Props): React.ReactElement {
    const handleAnalyticsAndPageLoad = () => {
        const toolService = UtilsService.shouldUseABTestToolV2()
            ? ABTestToolServiceV2
            : ABTestToolService;
        toolService?.fireAbTestAnalytics(window.location.pathname);
        PageLoadService.handlePageAnalytics();
    };

    const {
        variantPerTest: { jan25_promo: jan25PromoVariant }
    } = useABTestContext();
    const isJan25Promo = jan25PromoVariant === 'jan25';

    useEffect(() => {
        UtilsService.reloadOTBanner();
        UtilsService.storeInitialUtms();
        UtilsService.storeCoupon(undefined, isJan25Promo);
        UtilsService.handleFullStory();
        handleAnalyticsAndPageLoad();
        CloudflareBannerService.hideAndTrackBanner();
    }, []);

    return (
        <div className={`${customClass ?? ''}`}>
            <ABTestFallbackVariants />

            <OGTags {...ogTags} lang={lang} />
            {!noNav && (
                <>
                    {navBarTools ? (
                        <NavBarTools />
                    ) : (
                        <NavBar slimNav={slimNav} noCta={noCta} />
                    )}
                </>
            )}
            {children}
            {!noFooter && !slimFooter && <Footer />}
            {slimFooter && <FooterSlim />}
            {mobileStickyCTA && <MobileStickyCTA />}
        </div>
    );
}

export default memo(Layout);
