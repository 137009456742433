import { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import HBButton, { TButtonStyle } from '../honeybook-ui/HBButton/HBButton';
import HBSSOButton from '../honeybook-ui/HBSSOButton/HBSSOButton';
import GeolocationService, { GeoData } from '../../Services/GeolocationService';
import RegistrationService from '../../Services/RegistrationService';
import AnalyticsService, {
    AnalyticsEvents,
    GTM_ANALYTICS_EVENTS
} from '../../Services/AnalyticsService';
import StorageService from '../../Services/StorageService';
import { CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY } from '../../constants/constants';
import { NonUsaWarningModalWrapper } from '../NonUsaWarningModalWrapper/NonUsaWarningModalWrapper';
import { useABTestContext } from '../../ab-tests/context/useABTestContext';
import './registration-form.scss';
import { Coupons } from '../../constants/promoCodes';
import { getIsCtaTestCopyByPath } from '../../Services/UtilsService';

interface Props {
    showSSO?: boolean;
    showNoCcRequired?: boolean;
    direction?: 'row' | 'column';
    size?: 'x-small' | 'small' | 'medium' | 'large';
    customClass?: string;
    source: string;
    templateId?: string;
    buttonText?: string;
    dataForIntakeFormTest?: Record<string, any>;
    buttonStyle?: TButtonStyle;
    couponCode?: string;
    testId?: string;
    signupRedirectUrl?: string;
    handleSignupRedirectUrlCallback?: () => void;
    shouldShowButtonLoader?: boolean;
}

const RegistrationForm = ({
    showSSO,
    showNoCcRequired,
    direction = 'column',
    size = 'medium',
    customClass,
    source,
    templateId,
    buttonText,
    dataForIntakeFormTest,
    buttonStyle,
    couponCode,
    testId,
    signupRedirectUrl,
    handleSignupRedirectUrlCallback,
    shouldShowButtonLoader
}: Props) => {
    const { t } = useTranslation();
    const {
        variantPerTest: { cta_text_test, jan25_promo: jan25PromoVariant }
    } = useABTestContext();
    const [isOpenNonUsaModal, setIsOpenNonUsaModal] = useState(false);
    const [isValidGeolocation, setIsValidGeolocation] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [geoLocationData, setGeoLocationData] = useState<
        GeoData | undefined
    >();

    const isJan25Promo = jan25PromoVariant === 'jan25';
    const _couponCode = isJan25Promo ? Coupons.jan25 : couponCode;

    const isTest = cta_text_test === 'test';

    const defaultButtonText = isTest
        ? t('registrationForm.tryForFree')
        : t('registrationForm.startFreeTrial');
    const [buttonTestText, setButtonTestText] = useState(defaultButtonText);

    useEffect(() => {
        if (getIsCtaTestCopyByPath()) {
            setButtonTestText(t('registrationForm.tryForFree'));
        }

        if (!!signupRedirectUrl) {
            if (!isValidGeolocation) {
                handleNonUsaModal();
                return;
            }
            completeRegistrationForm();
        }
    }, [signupRedirectUrl, isValidGeolocation]);

    useEffect(() => {
        let isMounted = true;

        GeolocationService.isFromValidCountriesPromise()
            .then(isValid => {
                if (isMounted) setIsValidGeolocation(isValid);
            })
            .catch(error => console.error(error));

        GeolocationService.getLocationPromise()
            .then(data => {
                if (isMounted) setGeoLocationData(data);
            })
            .catch(error => console.error(error));

        return () => {
            isMounted = false;
        };
    }, []);

    const getSignupLandingPage = (locationPathname: string): string => {
        let signupLandingPage = locationPathname.split('?')[0];

        if (signupLandingPage[0] === '/') {
            signupLandingPage = signupLandingPage.substring(1);
        }

        if (signupLandingPage === '') {
            signupLandingPage = 'home';
        }

        return signupLandingPage;
    };

    const trackAnalytics = (eventName: string): void => {
        AnalyticsService.track(eventName);
    };

    const completeRegistrationForm = () => {
        const data =
            RegistrationService.handleRegistrationWithoutFields(
                geoLocationData
            );
        if (data && !data.promo && _couponCode) {
            data.promo = _couponCode;
        }

        if (dataForIntakeFormTest) {
            data.dataForIntakeFormTest = dataForIntakeFormTest;
        }
        if (signupRedirectUrl) {
            data.signupRedirectUrl = signupRedirectUrl;
        }

        RegistrationService.onRegistrationSuccessWithoutFields(data);
    };

    const handleNonUsaModal = () => {
        setIsOpenNonUsaModal(true);
        setIsLoading(false);
        AnalyticsService.track(
            AnalyticsEvents.on_boarding_non_usa_warning_view
        );
    };

    const handleSubmit = async () => {
        if (shouldShowButtonLoader) {
            return;
        }

        setIsLoading(true);

        handleSignupRedirectUrlCallback?.();

        AnalyticsService.trackGtm(GTM_ANALYTICS_EVENTS.cta_click, {
            click_text: buttonText || buttonTestText,
            section: source
        });

        const additionalDataFromSessionStorage: Record<string, unknown> =
            StorageService.getItem(
                CLICK_START_TRIAL_ADDITIONAL_DATA_STORAGE_KEY,
                'sessionStorage'
            ) || {};

        const globalAdditionalDataPerPath =
            additionalDataFromSessionStorage[window.location.pathname] || {};

        const globalAdditionalData = Object.values(
            globalAdditionalDataPerPath
        ).reduce<Record<string, unknown>>(
            (data, current) => ({ ...data, ...current }),
            {}
        );

        AnalyticsService.trackClick(AnalyticsEvents.start_trial, {
            source,
            signup_landing_page: getSignupLandingPage(window.location.pathname),
            platform: 'gatsby',
            start_trial_variant: 'single-cta',
            ...globalAdditionalData
        });

        AnalyticsService.reportEvent('Intake Form Started');

        // if handleSignupRedirectUrlCallback exist and signupRedirectUrl is empty we run completeRegistrationForm in useEffect after signupRedirectUrl is updated
        if (!!handleSignupRedirectUrlCallback && !signupRedirectUrl) {
            return;
        }
        if (!isValidGeolocation) {
            handleNonUsaModal();
            return;
        }

        completeRegistrationForm();
    };

    return (
        <section
            className={`registration-form__container registration-form__container--${direction} ${
                customClass || ''
            }`.trim()}
        >
            <NonUsaWarningModalWrapper
                isOpenNonUsaModal={isOpenNonUsaModal}
                onCloseNonUsaModal={() => setIsOpenNonUsaModal(false)}
                onConfirmNonUsaModal={completeRegistrationForm}
            />

            <div className="registration-form__form-container">
                <HBButton
                    isDisabled={isLoading}
                    isLoading={isLoading || shouldShowButtonLoader}
                    size={size}
                    data-testid={testId || 'start-trial--button'}
                    type="submit"
                    text={buttonText || buttonTestText}
                    buttonStyle={buttonStyle}
                    onClick={handleSubmit}
                />
                {showNoCcRequired && (
                    <div className="registration-form__no-cc-required">
                        {t('registrationForm.noCcRequired') as string}
                    </div>
                )}
            </div>

            {false && showSSO && (
                <div className="registration-form__sso-container">
                    <HBSSOButton
                        successCallback={RegistrationService.successSSO}
                        type="google"
                        trackAnalytics={trackAnalytics}
                        analyticsEvents={{
                            signup_with_google:
                                AnalyticsEvents.signup_with_google,
                            signup_with_google_successful:
                                AnalyticsEvents.signup_with_google_successful
                        }}
                    />
                </div>
            )}
        </section>
    );
};

export default memo(RegistrationForm);
