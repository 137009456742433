import { usePromoCode } from './usePromoCode';
import { useCallback, useMemo, useState } from 'react';
import AnalyticsService, {
    AnalyticsEvents
} from '../Services/AnalyticsService';
import UtilsService from '../Services/UtilsService';
import { MAP_OF_PROMO_CODES_CONFIG } from '../constants/promoCodes';
import type { TPlansForDisplay } from './../types/util-types';
import type { TPricingPlans } from '../types/util-types';

const PRICING_DATA = {
    starter: {
        planId: 'starter',
        monthly: 19,
        annualMonthly: 16,
        newMonthly: 36,
        newAnnual: 29
    },
    essentials: {
        planId: 'essentials',
        monthly: 39,
        annualMonthly: 32,
        newMonthly: 59,
        newAnnual: 49
    },
    premium: {
        planId: 'premium',
        monthly: 79,
        annualMonthly: 66,
        newMonthly: 129,
        newAnnual: 109
    }
};

export const usePricingPlans = () => {
    const { promoCode } = usePromoCode();
    const [isPriceMonthly, setIsPricingMonthly] = useState(false);
    const discountValue = MAP_OF_PROMO_CODES_CONFIG[promoCode].discount;

    const togglePricing = useCallback(() => {
        setIsPricingMonthly(!isPriceMonthly);
        AnalyticsService.trackClick(AnalyticsEvents.pricing_toggle, {
            source: `pricing toggle - ${isPriceMonthly ? 'monthly' : 'annual'} `
        });
    }, [isPriceMonthly]);

    const plansForDisplay: TPlansForDisplay[] = useMemo(() => {
        const currentPlanPeriod = isPriceMonthly ? 'monthly' : 'annualMonthly';
        return Object.keys(PRICING_DATA).map((plan: TPricingPlans) => {
            const isPlanEligibleForDiscount =
                !discountValue[
                    currentPlanPeriod
                ]?.excludedFromDiscount?.includes(plan);
            return {
                plan,
                originalPrice: PRICING_DATA[plan][currentPlanPeriod],
                ...(discountValue && {
                    priceAfterDiscount: UtilsService.getPriceAfterDiscount(
                        PRICING_DATA[plan][currentPlanPeriod],
                        isPlanEligibleForDiscount
                            ? discountValue[currentPlanPeriod]
                                  .discountPercentage
                            : undefined,
                        discountValue[currentPlanPeriod].fixedPrice
                    )
                }),
                ...(discountValue &&
                    isPlanEligibleForDiscount && {
                        discountPercentage:
                            discountValue[currentPlanPeriod].discountPercentage
                    }),
                newPrice: isPriceMonthly
                    ? PRICING_DATA[plan].newMonthly
                    : PRICING_DATA[plan].newAnnual
            };
        });
    }, [discountValue, isPriceMonthly]);
    return {
        togglePricing,
        plansForDisplay,
        isPriceMonthly,
        defaultToggleIdx: 1
    };
};
