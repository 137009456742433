import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import FeatureIntentHeroBlock from '../components/FeatureBlocks/FeatureIntentHeroBlock/FeatureIntentHeroBlock';
import StatsAnimation from '../components/Hero/StatsAnimation/StatsAnimation';
import FeatureSignatureBlock from '../components/FeatureBlocks/FeatureSignatureBlock/FeatureSignatureBlock';
import FeatureInfoBlock, {
    IFeature
} from '../components/FeatureBlocks/FeatureInfoBlock/FeatureInfoBlock';
import FeatureLeadAnimation from '../components/FeatureBlocks/FeatureInfoBlock/Animations/FeatureLeadAnimation';
import FeatureAutomationsAnimation from '../components/FeatureBlocks/FeatureInfoBlock/Animations/FeatureAutomationsAnimation';
import FeatureClientAnimation from '../components/FeatureBlocks/FeatureInfoBlock/Animations/FeatureClientAnimation';
import FeaturePipelineAnimation from '../components/FeatureBlocks/FeatureInfoBlock/Animations/FeaturePipelineAnimation';
import FeatureIntegrationBlock from '../components/FeatureBlocks/FeatureIntegrationBlock/FeatureIntegrationBlock';
import FeatureTemplateGallery, {
    CustomTemplateData
} from '../components/FeatureBlockElements/FeatureTemplateGallery/FeatureTemplateGallery';
import FeatureTestimonialCarouselBlock from '../components/FeatureBlocks/FeatureTestimonialCarouselBlock/FeatureTestimonialCarouselBlock';
import PromoBlockLeft from '../components/FeatureBlocks/PromoBlockLeft/PromoBlockLeft';
import { usePromoCode } from '../hooks/usePromoCode';
import type { Quote } from '../types/testimonial-quote';

import '../styles/online-template.scss';

const ogTags = {
    url: 'https://www.honeybook.com/get-started-crm',
    title: 'CRM & Clientflow Management Platform',
    description:
        'Manage leads, projects, and client interactions in one place. Automate busywork, customize your pipeline, and give clients a great experience.',
    noIndex: true
};

const signatureCardContents = [
    {
        iconUrl: 'marketing_site/crm-page/signature/capture.svg',
        transId: 'capture'
    },
    {
        iconUrl: 'marketing_site/crm-page/signature/relationships.svg',
        transId: 'relationships'
    },
    {
        iconUrl: 'marketing_site/crm-page/signature/manage.svg',
        transId: 'manage'
    }
];

const testimonials: Quote[] = [
    {
        profileImage: 'marketing_site/crm-page/testimonials/jody.png',
        transId: 'featureTestimonialCarouselBlock.testimonials.jody',
        name: 'Angie M.',
        logo: 'marketing_site/crm-page/testimonials/weddings_and_events.png'
    },
    {
        profileImage: 'marketing_site/crm-page/testimonials/natasha.png',
        transId: 'featureTestimonialCarouselBlock.testimonials.natasha',
        name: 'Dianuh A.',
        logo: 'marketing_site/crm-page/testimonials/marketing_consultant.png'
    },
    {
        profileImage: 'marketing_site/crm-page/testimonials/heather.png',
        transId: 'featureTestimonialCarouselBlock.testimonials.heather',
        name: 'Danielle J.',
        logo: 'marketing_site/crm-page/testimonials/business_coach.png'
    }
];

const templateData: CustomTemplateData[] = [
    {
        templateId: '61633ab31dd45702a0c16b8f',
        transId: 'templates.clientService',
        imageUrl: 'marketing_site/online-contracts/templates/1.png'
    },
    {
        templateId: '647ee710b061802bece34967',
        transId: 'templates.bookingFile',
        imageUrl:
            'marketing_site/online-invoices/03_Templates/Desktop/Card_allinonebookingfile.png'
    },
    {
        templateId: '647ee77cca13550454a38200',
        transId: 'templates.minimalProposal',
        imageUrl:
            'marketing_site/online-invoices/03_Templates/Desktop/Card_minimalproposal.png'
    },
    {
        templateId: '61e07925845559016c7af36c',
        transId: 'templates.servicesAndPricingGuide',
        imageUrl:
            'marketing_site/crm-page/templates/services_and_pricing_guide.png'
    },
    {
        templateId: '616336771dd45702a0c16b07',
        transId: 'templates.simpleContract',
        imageUrl: 'marketing_site/online-contracts/templates/5.png'
    },
    {
        templateId: '63d90d77ffaf4f0310911b4b',
        transId: 'templates.instantBooking',
        imageUrl:
            'marketing_site/online-invoices/03_Templates/Desktop/Card_instantbooking.png'
    }
];

interface Props {
    pageContext: {
        contentKey: string;
        ogTagUrl: string;
    };
}

const CrmPage = ({
    pageContext: {
        contentKey = 'default',
        ogTagUrl = 'https://www.honeybook.com/get-started-crm'
    }
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const { promoCode } = usePromoCode();

    const stats = useMemo(
        () => [
            {
                id: 'leads',
                title: <Trans>{t('statsMarqueeAnimation.leads.title')}</Trans>,
                subtitle: (
                    <Trans>{t('statsMarqueeAnimation.leads.subtitle')}</Trans>
                )
            },
            {
                id: 'paid',
                title: <Trans>{t('statsMarqueeAnimation.paid.title')}</Trans>,
                subtitle: (
                    <Trans>{t('statsMarqueeAnimation.paid.subtitle')}</Trans>
                )
            },
            {
                id: 'revenue',
                title: (
                    <Trans>{t('statsMarqueeAnimation.revenue.title')}</Trans>
                ),
                subtitle: (
                    <Trans>{t('statsMarqueeAnimation.revenue.subtitle')}</Trans>
                )
            },
            {
                id: 'invoices',
                title: (
                    <Trans>{t('statsMarqueeAnimation.invoices.title')}</Trans>
                ),
                subtitle: (
                    <Trans>
                        {t('statsMarqueeAnimation.invoices.subtitle')}
                    </Trans>
                )
            }
        ],
        []
    );

    const features: IFeature[] = useMemo(
        () => [
            {
                transKey: 'lead',
                animationCmp: (
                    <FeatureLeadAnimation customClass="feature-info-block__animation" />
                )
            },
            {
                transKey: 'automations',
                animationCmp: (
                    <FeatureAutomationsAnimation customClass="feature-info-block__animation" />
                )
            },
            {
                transKey: 'client',
                animationCmp: (
                    <FeatureClientAnimation customClass="feature-info-block__animation" />
                )
            },
            {
                transKey: 'pipeline',
                animationCmp: (
                    <FeaturePipelineAnimation customClass="feature-info-block__animation" />
                )
            }
        ],
        []
    );

    return (
        <Layout
            ogTags={{ ...ogTags, url: ogTagUrl }}
            slimNav
            slimFooter
            mobileStickyCTA
        >
            <div className="online-template online-template--crm">
                <FeatureIntentHeroBlock
                    title={t(`featureIntentHeroBlock.${contentKey}.title`)}
                    subtitle={t(
                        `featureIntentHeroBlock.${contentKey}.subtitle`
                    )}
                    intentTitle={t(
                        `featureIntentHeroBlock.${contentKey}.intentTitle`
                    )}
                    imgUrl={'marketing_site/crm-page/crm-hero-v4.png'}
                />
                <StatsAnimation stats={stats} />
                <FeatureSignatureBlock
                    buttonStyle="primary"
                    cardContents={signatureCardContents}
                    ctaSource="crm signature block"
                    transId={`featureSignatureBlock.${contentKey}`}
                />
                <FeatureInfoBlock transId={contentKey} features={features} />
                <FeatureIntegrationBlock
                    title={t(`featureIntegrationBlock.${contentKey}.title`)}
                    subtitle={t(
                        `featureIntegrationBlock.${contentKey}.subtitle`
                    )}
                />
                <FeatureTemplateGallery
                    customTemplateData={templateData}
                    ctaSource="crm template gallery"
                />
                <FeatureTestimonialCarouselBlock testimonials={testimonials} />
                <PromoBlockLeft
                    customClass="verticals-paid-page--new__promo-block-left promo-block-left--yellow"
                    title={t(`${promoCode}.promoBlockLeft.title`)}
                    subtitle={t(`${promoCode}.promoBlockLeft.subtitle`, {
                        defaultValue: null
                    })}
                    source="promo block left - crm"
                    promoCode={promoCode}
                />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "crm"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default CrmPage;
